<template>
  <div v-if="contributors" class="accordion" id="contribAccordion">
    <div class="accordion-item" v-for="(contributor, index) in contributors" :key="index">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" :class="{'accordion-button-no-icon': !supplierHasInfo(contributor)}" type="button" data-bs-toggle="collapse" :data-bs-target="supplierHasInfo(contributor) ? '#contrib'+index : 'contribAccordion'">
          <div class="d-flex flex-row">
            <div style="width:64px" class="mx-auto my-auto">
              <img v-if="contributor.icon" :src="'/dl/pic/'+contributor.icon" style="max-width: 64px; max-height: 64px; width: auto; height: auto;">
              <svg v-else style="width: 64px; height: 64px;"><use xlink:href="#bat1"/></svg>
            </div>
            <div class="ms-2">
              <h6>{{ contributor.name }}</h6>
              <p>{{$tc('trends.contribCounts1', {n: contributor.cnt})}} {{$tc('trends.contribCounts2', {n: contributor.locs})}}</p>
            </div>
          </div>
        </button>
      </h2>
      <div :id="'contrib'+index" class="accordion-collapse collapse" data-bs-parent="#contribAccordion" v-if="supplierHasInfo(contributor)">
        <div class="accordion-body">
          <p v-if="contributor.description" class="text-muted">{{contributor.description}}</p>
          <p v-if="contributor.contributors">{{contributor.contributors}}</p>
          <p v-if="contributor.url"><a :href="contributor.url">{{contributor.url}}</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import MyBase from "@/components/trends/MyBase.vue";

export default defineComponent({
  extends: MyBase,
  name: 'ContributorsAccordion',
  components: {},
  props: {
    contributors: Object,
  },
  data: function (){
    return {
    }
  },
  mounted: function() {
    return;
  },
  methods: {
    supplierHasInfo: function (supplier){
      if (supplier.url || supplier.desc || supplier.contributors) return true;
      return false;
    },
  },
});
</script>